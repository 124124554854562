<script>
	import Base from '@backend/Base.vue';
	import BOGen from '@helper/BOGen';
	import Gen from '@helper/Gen';

	export default {
		name: "BoHome",
		extends: Base,
		data() {
			return {
				Name: "BoHome",
				mrLevel: {},
				row: {},
				row2: {},
				row3: {},
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData(() => {
				setTimeout(() => {
					this.$set(this.row, 'type', 'update')
				}, 500)
			})
		},
		methods: {
			submitHero(e) {
				if (e && e.btnLoading()) return;
				BOGen.apirest('/' + Page.Name, {
					data: this.row,
					type: 'updateHero'
				}, (err, resp) => {
					if (e) e.btnUnloading()
					if (resp.error) return Gen.info(resp.message, resp.style, 3000, '.hero-info')
					if (resp.success) {
						Gen.info(resp.message, resp.style, 3000, '.hero-info')
						setTimeout(() => {
							$("#collapseExample").hide()
						}, 3000);
						this.refreshData()
					}
				}, "POST");
			},
			submitOurDiscovery(e) {
				if (e && e.btnLoading()) return;
				BOGen.apirest('/' + Page.Name, {
					data: this.row2,
					type: 'updateCapeDiscovery'
				}, (err, resp) => {
					if (e) e.btnUnloading()
					if (resp.error == 1) return Gen.info(resp.message, resp.style, 3000, '.info1')
					if (resp.success) {
						Gen.info(resp.message, resp.style, 3000, '.info1')
						this.refreshData()
					}
				}, "POST");
			},
			submitOurBillasimo(e) {
				if (e && e.btnLoading()) return;
				BOGen.apirest('/' + Page.Name, {
					data: this.row3,
					type: 'updateBelasimo'
				}, (err, resp) => {
					if (e) e.btnUnloading()
					if (resp.error == 1) return Gen.info(resp.message, resp.style, 3000, '.info2')
					if (resp.success) {
						Gen.info(resp.message, resp.style, 3000, '.info2')
						this.refreshData()
					}
				}, "POST");
			},
			showEdit() {
				if ($("#collapseExample").css("display") == "none") $("#collapseExample").show()
				else $("#collapseExample").hide()
			}
		},
		watch: {
			'$route.query'(v) {
				this.refreshData()
			}
		}
	};
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>
		<div class="row">
			<div class="col-12">
				<div class="card">
					<VForm @resp="submitHero" method="post">
						<div class="card-body">
							<h5 class="card-title">Hero Image</h5>
							<div class="row">
								<div class="col-md-8">
									<div class="wrap_slider_img">
										<img :src="uploader(row.shi_image_desktop)" class="img-responsive" />
										<div class="slider_name">
											<p>Hero Image</p>
										</div>
										<a href="javascript:;" v-if="page.moduleRole('Update')" @click="showEdit" class="bullet_edit"><i
												class="fas fa-pencil-alt"></i></a>
									</div>
								</div>
							</div>
							<div class="row mt-4" style="display:none" id="collapseExample">
									<div class="hero-info col-12 mb-12">asd</div>
								<div class="col-md-4 mb-3">
									<BoField name="shi_image_desktop" mandatory>
										<Uploader :param="{thumbnail:true}" name="shi_image_desktop" v-model="row.shi_image_desktop"
											type="hero_descktop" uploadType="cropping"></Uploader>
									</BoField>
									<BoField name="shi_image_mobile" mandatory>
										<Uploader :param="{thumbnail:true}" name="shi_image_mobile" v-model="row.shi_image_mobile"
											type="hero_mobile" uploadType="cropping"></Uploader>
									</BoField>
								</div>
								<div class="col-md-8 mb-3">
									<div class="row">
										<div class="col-md-12">
											<div class="panel_group">
												<div class="panel_heading">Heading</div>
												<div class="panel_body">
													<div class="row">
														<div class="col-md-9">
															<BoField name="shi_title_header_en" v-model="row.shi_title_header_en"></BoField>
														</div>
														<div class="col-md-9">
															<BoField name="shi_title_header_id" v-model="row.shi_title_header_id"></BoField>
														</div>
														<div class="col-md-2">
															<BoField :attr="{type:'color'}" name="shi_title_header_color"
																v-model="row.shi_title_header_color"></BoField>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-md-12">
											<div class="row">
												<div class="col-md-12">
													<div class="panel_group">
														<div class="panel_heading">Sub-Heading</div>
														<div class="panel_body">
															<div class="row">
																<div class="col-md-9">
																	<div class="form-group">
																		<BoField name="shi_title_subheader_en" v-model="row.shi_title_subheader_en">
																		</BoField>
																	</div>
																</div>

																<div class="col-md-9">
																	<BoField name="shi_title_subheader_id" v-model="row.shi_title_subheader_id">
																	</BoField>
																</div>
																<div class="col-md-2">
																	<div class="form-group">
																		<label class="control-label">Color </label>
																		<input class="form-control" type="color" id="favcolor" name="favcolor"
																			v-model="row.shi_title_subheader_color">
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-4 mt-3">
											<button type="submit" v-if="page.moduleRole('Update')"
												class="btn btn-rounded btn-block btn-info btn-loading">Submit</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</VForm>
				</div>
			</div>

			<div class="col-12">
				<VForm @resp="submitOurDiscovery" method="post">
					<div class="card">
						<div class="card-body">
							<h5 class="card-title">Our Cape Discovery</h5>
							<hr>
							<div class="row">
								<div class="col-12 info1"></div>
								<div class="col-md-3">
									<BoField :label="'Image on Left Side'" name="sc_image1" mandatory>
										<Checkbox v-model="row2.sc_show_image" class="home-check">Active ?</Checkbox>
										<Uploader :param="{thumbnail:true}" name="sc_image1" v-model="row2.sc_image1" type="our_cap_static"
											uploadType="cropping"></Uploader>
										<span v-if="row2.sc_show_image==1" class="label label-success">Active</span>
										<span v-else class="label label-danger">Inactive</span>
									</BoField>
								</div>
								<div class="col-md-6">
									<div class="desc-preview">
										<BoField :label="'Content Description (EN)'" name="sc_desc_en" mandatory>
											<textarea :mandatory="true" cols="30" rows="9" class="form-control"
												v-model="row2.sc_desc_en"></textarea>
										</BoField>
										<BoField :label="'Content Description (ID)'" name="sc_desc_id">
											<textarea cols="30" rows="9" class="form-control" v-model="row2.sc_desc_id"></textarea>
										</BoField>
									</div>
								</div>
								<div class="col-md-3">
									<BoField :label="'Image on Right Side'" name="sc_image2" mandatory>
										<Checkbox v-model="row2.sc_show_image2" class="home-check">Active ?</Checkbox>
										<Uploader :param="{thumbnail:true}" name="sc_image2" v-model="row2.sc_image2" type="our_cap_static"
											uploadType="cropping"></Uploader>
										<span v-if="row2.sc_show_image2==1" class="label label-success">Active</span>
										<span v-else class="label label-danger">Inactive</span>
									</BoField>
								</div>
								<div class="col-12 mt-3 text-right">
									<button type="submit" v-if="page.moduleRole('Update')"
										class="btn btn-rounded btn-info btn-loading">Submit</button>
								</div>
							</div>
						</div>
					</div>
				</VForm>
			</div>

			<div class="col-12">
				<VForm @resp="submitOurBillasimo" method="post">
					<div class="card">
						<div class="card-body">
							<h5 class="card-title">Our Bellisimo</h5>
							<hr>
							<div class="row">
								<div class="col-12 info2"></div>
								<div class="col-md-3">
									<BoField :label="'Image on Left Side'" name="sc_image1" mandatory>
										<Checkbox v-model="row3.sc_show_image" class="home-check">Active ?</Checkbox>
										<Uploader :param="{thumbnail:true}" name="sc_image1" v-model="row3.sc_image1" type="our_cap_static"
											uploadType="cropping"></Uploader>
										<span v-if="row3.sc_show_image==1" class="label label-success">Active</span>
										<span v-else class="label label-danger">Inactive</span>
									</BoField>
								</div>
								<div class="col-md-6">
									<div class="desc-preview">
										<BoField :label="'Content Description (EN)'" name="sc_desc_en" mandatory>
											<textarea :mandatory="true" cols="30" rows="9" class="form-control"
												v-model="row3.sc_desc_en"></textarea>
										</BoField>
										<BoField :label="'Content Description (ID)'" name="sc_desc_id">
											<textarea cols="30" rows="9" class="form-control" v-model="row3.sc_desc_id"></textarea>
										</BoField>
									</div>
								</div>
								<div class="col-md-3">
									<BoField :label="'Image on Right Side'" name="sc_image2" mandatory>
										<Checkbox v-model="row3.sc_show_image2" class="home-check">Active ?</Checkbox>
										<Uploader :param="{thumbnail:true}" name="sc_image2" v-model="row3.sc_image2" type="our_cap_static"
											uploadType="cropping"></Uploader>
										<span v-if="row3.sc_show_image2==1" class="label label-success">Active</span>
										<span v-else class="label label-danger">Inactive</span>
									</BoField>
								</div>
								<div class="col-12 mt-3 text-right">
									<button type="submit" v-if="page.moduleRole('Update')"
										class="btn btn-rounded btn-info btn-loading">Submit</button>
								</div>
							</div>
						</div>
					</div>
				</VForm>
			</div>
		</div>
	</div>
</template>